<template>
    <div class="pt-32 flex flex-col items-center px-4">
        <van-cell-group class="md:w-1/2 w-full mb-8 shadow-md" inset v-for="teacher in teachers" :key="teacher.userId">
            <profile-list-cell :user="teacher" :active-text="'在职'" :show-active="true"
                @click-icon="showProfile(teacher)" @remove-personal-tag="removePersonalTag"
                @remove-system-tag="removeSystemTag" @show-tag-input="showTagInput" @report-active="reportActive"
                :show-log="canManageStudent" />

            <van-cell :title="StatTypes.totalStudent.name['zh']" :value="teacher.stats.totalStudent" />
            <van-cell :title="StatTypes.totalClass.name['zh']" :value="teacher.stats.totalClass" />

            <van-cell v-if="canManageStudent">
                <template #value>
                    <div class="flex flex-row-reverse flex-wrap">
                        <van-button class="ml-4 mb-2" plain type="primary" size="small"
                            @click="goTeacherClass(teacher)">教师管理</van-button>
                    </div>
                </template>
            </van-cell>
        </van-cell-group>
    </div>
    <profile-viewer ref="viewProfileRef" :lang-code="'zh'" />
    <van-dialog v-model:show="showAddTag" title="添加系统标签" show-cancel-button @confirm="putTag()">
        <div class="text-center">
            <input class="border border-purple-500 w-10/12 p-1 my-4" type="text" v-model="newTag"
                @keyup.enter="putTag()" />
        </div>
    </van-dialog>
</template>

<script>
import {
    ref,
    computed,
    onMounted
} from "vue";
import {
    useStore
} from "vuex";
import {
    useRoute,
    useRouter
} from "vue-router";
import {
    Cell,
    CellGroup,
    Button,
    Toast,
    Dialog
} from "vant";
import Env from "@/logics/Envs.js";
import ListTeacher from "@/asyncs/ListTeacher.js";
import ProfileViewer from "@/components/dialog/ProfileViewer.vue";
import ProfileListCell from "@/components/list/ProfileListCell.vue";
import StatTypes from "@/static_values/StaticStatTypes.js";
import StartUser from "@/asyncs/StartUser.js";
import GetSession from "@/asyncs/GetSession.js";
import AddTag from "@/asyncs/AddTag.js";
import RemoveTag from "@/asyncs/RemoveTag.js";

export default {
    components: {
        [Cell.name]: Cell,
        [CellGroup.name]: CellGroup,
        [Button.name]: Button,
        [Toast.name]: Toast,
        [Dialog.Component.name]: Dialog.Component,
        ProfileViewer,
        ProfileListCell
    },
    setup() {
        const store = useStore();
        store.commit("showBack");
        store.commit("showMenu");
        store.commit("setPageTitle", "教师列表");
        const router = useRouter();
        const route = useRoute();
        const teachers = ref([]);
        onMounted(() => {
            GetSession({
                store,
                router,
                route
            }, async function () {
                teachers.value = await ListTeacher();
            });
        });
        const viewProfileRef = ref(null);
        const showProfile = function (teacher) {
            viewProfileRef.value.showUser(teacher);
        };

        const reportActive = async function (user, active) {
            const ok = await StartUser(user.userId, active);
            if (ok) {
                user.active = active;
            }
        };

        const showAddTag = ref(false);
        var userTagsForAdd = null;
        var userAddTag = null;
        const newTag = ref("");

        const showTagInput = function (userTags, userId) {
            userTagsForAdd = userTags;
            userAddTag = userId;
            showAddTag.value = true;
        };
        const putTag = function () {
            if (!newTag.value.trim()) {
                Toast("输入的标签不能为空");
            } else {
                if (!userTagsForAdd.system) {
                    userTagsForAdd.system = [];
                }
                AddTag(userAddTag, newTag.value,
                    function (tag) {
                        Toast("标签[" + tag + "]添加成功");
                        userTagsForAdd.system.push(tag);
                        userTagsForAdd = null;
                    },
                    function (e) {
                        Toast("标签添加失败: " + e);
                        userTagsForAdd = null;
                    }
                );
            }
            newTag.value = "";
            userAddTag = null;
            showAddTag.value = false;
        };

        const removePersonalTag = function (tagIndex, tags, userId) {
            RemoveTag(userId, tagIndex, "personal",
                function () {
                    Toast("标签移除成功");
                    tags.splice(tagIndex, 1);
                },
                function () {
                    Toast("标签移除失败");
                }
            );
        };

        const removeSystemTag = function (tagIndex, tags, userId) {
            RemoveTag(userId, tagIndex, "system",
                function () {
                    Toast("标签移除成功");
                    tags.splice(tagIndex, 1);
                },
                function () {
                    Toast("标签移除失败");
                }
            );
        };

        const goTeacherClass = function (teacher) {
            router.push("/teacherClass/" + teacher.userId);
        };

        return {
            Env,
            StatTypes,
            teachers,
            viewProfileRef,
            showProfile,
            reportActive,
            showAddTag,
            newTag,
            putTag,
            removePersonalTag,
            removeSystemTag,
            showTagInput,
            goTeacherClass,
            canManageStudent: computed(() => store.state.user.manStudent)
        };
    }
}
</script>
