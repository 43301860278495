export default {
    totalStudent: {
        name: {
            zh: "现总学生",
            en: "Total Students"
        }
    },
    totalClass: {
        name: {
            zh: "现总课数",
            en: "Total Classes"
        }
    }
}